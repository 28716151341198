import '../plugins/tabs';

/* eslint-disable */

// content tabs init
export default function initTabs () {
  jQuery('.tabset').tabset({
    tabLinks: 'a',
    defaultTab: true
  });
}
