import 'slick-slider/slick/slick';

export default function initSlick () {
  const $blogSlider = jQuery('.blog-slider');
  $blogSlider.slick({
    rows: 0,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  jQuery('.blog-slider-prev').on('click', () => {
    $blogSlider.slick('slickPrev');
  });
  jQuery('.blog-slider-next').on('click', () => {
    $blogSlider.slick('slickNext');
  });

  const $introSlider = jQuery('.intro-slider');
  $introSlider.slick({
    infinite: false,
    rows: 0,
    slidesToShow: 1,
    dots: false,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  const $blockquoteSlider = jQuery('.blockquote-slider');
  $blockquoteSlider.slick({
    rows: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
  });
  jQuery('.blockquote-slider-prev').on('click', () => {
    $blockquoteSlider.slick('slickPrev');
  });
  jQuery('.blockquote-slider-next').on('click', () => {
    $blockquoteSlider.slick('slickNext');
  });
}
