import '../plugins/mobileNav';
import '../utils/responsiveHelper';

/* eslint-disable */

// mobile menu init
export default function initMobileNav () {
  ResponsiveHelper.addRange({
    '..1024': {
      on: function () {
        jQuery('body').mobileNav({
          menuActiveClass: 'nav-active',
          menuOpener: '.js-nav-opener',
          hideOnClickOutside: false,
          toggleEvent: 'click'
        });
      },
      off: function () {
        jQuery('body').mobileNav('destroy');
      }
    }
  });
}
